import React from "react"
import { Typography, Container, Grid, Box, Link } from "@material-ui/core"
import { RoomOutlined } from "@material-ui/icons"

import LayoutComponent from "../../../../components/layout.component"

export default function Lollypop() {
  return (
    <LayoutComponent>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={5} direction="row" alignItems="center">
            <Grid item md={6}>
              <img
                src="https://uxofeverything.com/static/fd74a92dae8e6a73d4734d968afcea89/6b64fc795f0d7a02d5a2345750cfea49.jpg"
                alt="cover"
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <Box pb={2}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <img
                      src="https://uxofeverything.com/strapi/uploads/c6_1a7dbbbcbd.jpg"
                      alt="logo"
                      width="250px"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                Lollypop
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                Lollypop was founded by Mr. Anil Reddy back in 2013. The
                company's key strength lies in front-end development, experience
                design, and interface design.
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Average rate: $25-$49 per hour
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Employees: 50-249
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Website:{" "}
                <Link href="https://www.lollypop.design/" target="_blank">
                  https://www.lollypop.design/
                </Link>
              </Typography>
              <Box display="flex" flexDirection="row">
                <RoomOutlined fontSize="small" />
                <Typography variant="body2" component="p">
                  Address | #677, 9th Main Rd, Sector 7, HSR Layout, Bengaluru,
                  Karnataka 560102
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container justify="center" direction="row">
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                About
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" direction="row">
            <Grid item md={10}>
              <Typography variant="body1" component="p">
                Lollypop was founded by Mr. Anil Reddy back in 2013. The
                company's key strength lies in front-end development, experience
                design, and interface design. With a tagline 'crafting sweet
                experiences', the company was founded to create digital products
                that would put a smile on the user's faces. The team has worked
                for clients coming from different countries and has experience
                in building digital products for various industries, from
                healthcare to education to food to e-commerce to real estate and
                IT. The team provides bespoke designs to clients after learning
                in-depth about their business needs. The team has delivered
                successful products for both start-ups and established brands
                like Aditya Birla, Bajaj Finserv, and Stanford University, to
                name a few.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}
